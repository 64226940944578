




























































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      progress: {
        value: 85.0, // #FEE
        max: 100.0,
        interval: 0,
        classes: ['animated'],
      },
    };
  },
  computed: {
    fee(): number {
      return 15;
    },
    earn(): number {
      return 85;
    },
  },
  mounted() {
    if (this.$store.getters.IS_PRERENDERING) return;

    this.progress.value = 0;

    requestAnimationFrame(this.animateFillingProgressBar);

    // With too much lag, just set to 80 after 2.5s
    window.setTimeout(() => {
      this.progress.value = this.earn; // #FEE
    }, 2500);
  },
  methods: {
    animateFillingProgressBar() {
      if (this.progress.value < this.earn) { // #FEE
        this.progress.value += 1.0;
      } else {
        this.progress.value = this.earn; // #FEE
      }

      requestAnimationFrame(this.animateFillingProgressBar);
    },
  },
  meta() {
    return {
      title: this.$t('titleChunk').toString(),
    };
  },
});
